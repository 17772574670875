import React from 'react';
import { Tooltip } from "react-tooltip";

function TablePagination({ itemsPerPage, indexOfFirstItem, indexOfLastItem, totalItems, currentPage, paginate }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="flex px-10 py-2 flex-col items-center sm:flex-row sm:items-center sm:justify-between">
                <div className="ml-3 first:ml-0" >
                    <Tooltip id="Prev-Page" />
                    {currentPage > 1 ? (
                        <button
                            onClick={() => paginate(currentPage - 1)}
                            className=""
                            data-tooltip-id="Prev-Page"
                            data-tooltip-content="Previous Page"
                            data-tooltip-place="top" 
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="stroke-[#ADBAC7] dark:stroke-[#656565]"><path d="m15 18-6-6 6-6" /></svg>
                        </button>
                    ) : (
                        <button className="cursor-not-allowed opacity-30" disabled>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="stroke-[#ADBAC7] dark:stroke-[#656565]"><path d="m15 18-6-6 6-6" /></svg>
                        </button>
                    )}
                </div>
                <div className="text-sm text-gray-500 text-center">
                    <span className="font-medium text-gray-600">
                        {indexOfFirstItem + 1}
                    </span>{' '}
                    -{' '}
                    <span className="font-medium text-gray-600">
                        {Math.min(indexOfLastItem, totalItems)}
                    </span>{' '}
                    of{' '}
                    <span className="font-medium text-gray-600">{totalItems}</span>
                </div>
                <div className="ml-3 first:ml-0" >
                    <Tooltip id="Next-Page" />
                    {currentPage < pageNumbers.length ? (
                        <button
                            onClick={() => paginate(currentPage + 1)}
                            className=""
                            data-tooltip-id="Next-Page"
                            data-tooltip-content="Next Page"
                            data-tooltip-place="top" 
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="stroke-[#ADBAC7] dark:stroke-[#656565]"><path d="m9 18 6-6-6-6" /></svg>
                        </button>
                    ) : (
                        <button  className="cursor-not-allowed opacity-30" disabled>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="stroke-[#ADBAC7] dark:stroke-[#656565]"><path d="m9 18 6-6-6-6" /></svg>
                        </button>
                    )}
                </div>

        </div>
    );
}

export default TablePagination;
