import React, { useState } from 'react'
import BugsTableItem from "./BugsTableItem"
import BugDetailsModal from '../../Modal/BugDetailsModal';

const ReportedBugsTable = ({ bugs }) => {
    const [bugModalOpen, setBugModalOpen] = useState(false);
    const [currentBugId, setCurrentBugId] = useState(null);
    
    const [bugList] = useState(() => bugs.map((bug) => bug.report_id));   

    const openModal = (bugId) => {
        setCurrentBugId(bugId);
        setBugModalOpen(true);
      };
    
    return (
        <>
        <div className="font-primary  relative">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto no-scrollbar">
                    <table className="table-auto w-full divide-y divide-gray-200 overflow-y-auto no-scrollbar">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">No.</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Id.</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Title</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Category</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Last Updated</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Status</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Assigned To</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">View / Delete</div>
                                </th>
                            </tr>
                        </thead>
                        {
                            bugs?.map((data, index) => {
                                return (
                                    <BugsTableItem  
                                        key={data?.report_id} 
                                        id={data?.report_id}
                                        num={index + 1}
                                        status={data?.status}
                                        title={data?.title}
                                        email={data?.reported_by}
                                        category={data?.category}
                                        time={data?.last_updated_time}
                                        assignee={data?.assigned_to}
                                        openModal={openModal}
                                    />
                                )
                            })
                        }
                    </table>
                </div>
            </div> 
        </div>
        {bugModalOpen && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 flex items-center justify-center px-4 sm:px-6">
                <BugDetailsModal
                    bugId={currentBugId}
                    modalOpen={bugModalOpen}
                    setModalOpen={setBugModalOpen}
                    bugList={bugList}
                    setCurrentBugId={setCurrentBugId}
                />
            </div>
        )}
    </>
    )
}

export default ReportedBugsTable