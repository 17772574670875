import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import BugTeamModal from '../../components/Modal/BugTeamModal';
import BugTeamTable from '../../components/Table/BugTeamTable/TeamTable';
import { setMember } from '../../slices/bugSlice';
import { addUserToBugTeam, fetchBugTeamMembers } from '../../services/operations/bugs';

const AddTeamMember = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const dispatch = useDispatch()

    const { handleSubmit, control, formState: { errors, isSubmitted }, reset } = useForm();

    const { teamMembers, member } = useSelector((state) => state.bugs)

    useEffect(() => {
        dispatch(fetchBugTeamMembers())
    }, [dispatch])

    useEffect(() => {
        dispatch(setMember({}))
    }, [dispatch]);

    useEffect(() => {
        reset(member)
    }, [reset, member]);

    const handleModalOpen = (e) => {
        e.stopPropagation();
        reset()
        const data = {
            username: '',
        }

        dispatch(setMember(data))
        setModalOpen(true);
    }
    
    const handleOnSubmit = async (data) => {
        const { username } = data;
        dispatch(addUserToBugTeam(username, setModalOpen));
    };


    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto no-scrollbar overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add Member</button>
                        </div>
                        {teamMembers && teamMembers.length > 0 ? (
                            <>
                                <BugTeamTable
                                    team={teamMembers}
                                    setModalOpen={setModalOpen}
                                />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No Team Members Added
                            </div>
                        )}
                        <BugTeamModal title={'Add Member'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(handleOnSubmit)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Username</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="username"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="username"
                                                    type="text"
                                                    name="username"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Team Member's Username"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'username is required',
                                            }}
                                        />
                                        {isSubmitted && errors.username && <p className="text-red-600 text-sm italic">{errors.username.message}</p>}
                                    </div>
                                </div>

                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Add Member</button>
                                    </div>
                                </div>
                            </form>
                        </BugTeamModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddTeamMember