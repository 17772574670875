import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { reportBug } from '../../services/operations/bugs';
import { BugIcon } from 'lucide-react';
import toast from 'react-hot-toast';

const ReportBug = () => {

    const dispatch = useDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState("");
    const [imagePreview, setImagePreview] = useState(image);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 500 * 1024) { 
                toast.error("File size should be less than 500 KB.");
                return;
            }
            setImage(file);
            setImageName(file.name);
            setImagePreview(URL.createObjectURL(file)); // Set preview URL for image
        }
    };

    const handleDeleteImage = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setImage(null);
        setImageName("");
        setImagePreview(null);
    };
    
    const { control, handleSubmit, formState: { errors }, reset } = useForm();

    // Reset form on page load
    useEffect(() => {
        reset();
    }, [reset]);

    const handleOnSubmit = async (data) => {
        dispatch(reportBug({ ...data, image}))
        setImage(null); // Clear image on submit
        setImagePreview(null); // Clear preview
    };


    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto no-scrollbar overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main className='items-center justify-center flex mt-10'>
                <div className="bg-white dark:bg-dark_50  text-light_30 dark:text-zinc-300 rounded-xl shadow-lg overflow-y-auto no-scrollbar max-w-[975px] w-full  max-h-full border border-gray-200 dark:border-zinc-800">
                    <div className='flex flex-col justify-center items-center w-full '>
                            <span className="flex items-center gap-4 mt-6">
                                <p className="text-2xl font-bold">Report a Bug</p>
                                <BugIcon
                                    height={26}
                                    width={26}
                                    // color={isDarkMode ? "#fafafa" : "#212B36"}
                                />
                            </span>
                            <p className="text-new_tertiary dark:text-new_dark_tertiary ">
                                Please provide details of the bug to be resolved soon{" "}
                            </p>
                        
                        <form onSubmit={handleSubmit(handleOnSubmit)} className="space-y-6 p-10 w-full">
                            {/* Category and Priority */}
                            <div className='flex justify-between gap-x-4 items-center w-full'>
                                
                            <div className='w-full'>
                                <label className="block text-md font-semibold mb-2">Issue Category</label>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({ field }) => (
                                        <select
                                            id="category"
                                            className="form-select w-full"
                                            {...field}
                                        >
                                            <option value="">Select Category</option>
                                            <option value="problem">Problem</option>
                                            <option value="editorial">Editorial</option>
                                            <option value="video">Video</option>
                                            <option value="tech">Tech</option>
                                            <option value="others">Others</option>
                                        </select>
                                    )}
                                    rules={{ required: "Category is required" }}
                                />
                                {errors.category && <p className="text-red-600 text-sm">{errors.category.message}</p>}
                            </div>

                            <div className='w-full'>
                                <label className="block text-md font-semibold mb-2">Priority</label>
                                <Controller
                                    name="priority"
                                    control={control}
                                    render={({ field }) => (
                                        <select
                                            id="priority"
                                            className="form-select w-full"
                                            {...field}
                                        >
                                            <option value="">Select Priority</option>
                                            <option value="high">High</option>
                                            <option value="mid">Mid</option>
                                            <option value="low">Low</option>
                                        </select>
                                    )}
                                    rules={{ required: "Priority is required" }}
                                />
                                {errors.priority && <p className="text-red-600 text-sm">{errors.priority.message}</p>}
                            </div>
                            </div>

                            {/* Title */}
                            <div>
                                <label className="block text-md font-semibold mb-2">Title</label>
                                <Controller
                                    name="title"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            id="title"
                                            type="text"
                                            placeholder="Enter a Title"
                                            className="form-input w-full"
                                            {...field}
                                        />
                                    )}
                                    rules={{ required: "Title is required" }}
                                />
                                {errors.title && <p className="text-red-600 text-sm">{errors.title.message}</p>}
                            </div>

                            {/* Description */}
                            <div>
                                <label className="block text-md font-semibold mb-2">Description</label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            id="description"
                                            placeholder="Tell us about your experience..."
                                            className="form-textarea w-full"
                                            rows="6"
                                            {...field}
                                        />
                                    )}
                                    rules={{
                                        required: "Description is required",
                                        minLength: { value: 10, message: "Description must be at least 10 characters" },
                                    }}
                                />
                                {errors.description && <p className="text-red-600 text-sm">{errors.description.message}</p>}
                            </div>

                            {/* Image Upload Section */}
                            <div>
                                <span className="block text-md font-semibold mb-2">Screenshot (optional)</span>
                                <div className="flex gap-x-4 items-center">
                                    {imagePreview && (
                                        <div className="flex items-center gap-4">
                                            <img src={imagePreview} alt="Preview" className=" ml-10 w-24 h-24 rounded-lg object-cover" />
                                            <div className="flex flex-row gap-x-4 justify-between items-center">
                                                <p className="text-sm text-gray-500 dark:text-gray-400">{imageName}</p>
                                                <button
                                                    onClick={handleDeleteImage}
                                                    type="button"
                                                    className="text-red-500 text-xs hover:underline mt-1"
                                                >
                                                    <div className={`font-medium rounded-full w-fit text-center bg-red-200 dark:bg-[#470C08] px-4 py-1 text-red-500 text-sm`}>
                                                        Remove
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    {!imagePreview && (
                                        <label
                                            htmlFor='dropzone-file'
                                            className='flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 dark:border-zinc-600 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-dark_50 dark:hover:bg-gray-800'
                                        >
                                            <div className='flex flex-col items-center justify-center pt-5 pb-6'>
                                                <svg
                                                    className='w-4 h-4 mb-3 text-gray-400'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    viewBox='0 0 24 24'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        strokeWidth='2'
                                                        d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                                                    ></path>
                                                </svg>
                                                <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                                                    <span className='font-semibold'>Click to upload</span> or drag and drop
                                                </p>
                                                <p className='text-xs text-gray-500 dark:text-gray-400'>PNG, JPG or JPEG (Max. 500KB)</p>
                                            </div>
                                            <input
                                                id='dropzone-file'
                                                className='hidden'
                                                type='file'
                                                name='image'
                                                accept='image/*'
                                                onChange={handleFileChange}
                                            />
                                        </label>
                                    )}
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div>
                                <button
                                    type="submit"
                                    className="bg-brand w-full rounded py-2 text-white"
                                >
                                    {"Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                </main>
            </div>
        </div>
    )
}

export default ReportBug