import React, {  useRef, useEffect } from 'react';
import Transition from '../../utils/Transition';
import { X } from 'lucide-react';

function DeleteModal({
    children,
    id,
    modalOpen,
    setModalOpen
}) {

    const modalContent = useRef(null);

    useEffect(() => {
      const clickHandler = ({ target }) => {
          if (!modalOpen || modalContent.current.contains(target)) return
          setModalOpen(false);
      };
      document.addEventListener('click', clickHandler);
      return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
      const keyHandler = ({ keyCode }) => {
          if (!modalOpen || keyCode !== 27) return;
          setModalOpen(false);
      };
      document.addEventListener('keydown', keyHandler);
      return () => document.removeEventListener('keydown', keyHandler);
  });

    return (
      <>
        <Transition
          className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
          show={modalOpen}
          enter="transition ease-out duration-200"
          enterStart="opacity-0"
          enterEnd="opacity-100"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
          aria-hidden="true"
        />
        <Transition
          id={id}
          className="fixed inset-0 z-50  flex items-center my-4 justify-center transform px-4 sm:px-6"
          role="dialog"
          aria-modal="true"
          show={modalOpen}
          enter="transition ease-in-out duration-200"
          enterStart="opacity-0 translate-y-4"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-in-out duration-200"
          leaveStart="opacity-100 translate-y-0"
          leaveEnd="opacity-0 translate-y-4"
        >
          <div
            ref={modalContent}
            className="p-7 bg-white  dark:bg-dark_50 dark:text-white rounded-md overflow-y-auto no-scrollbar max-w-[527px] w-full max-h-full border border-new_border dark:border-new_dark_border"
          >
            <div className="h-full">
              <div className="flex flex-row-reverse justify-between items-center">
                {/* <div className="font-semibold text-zinc-800 dark:text-zinc-100 text-xl">{title}</div> */}
                <button  onClick={(e) => { e.stopPropagation(); setModalOpen(false); }}>
                  <X />
                </button>
              </div>
            </div>
            {children}
          </div>
        </Transition>
      </>
    );
}

export default DeleteModal;
