/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { format } from "date-fns"
import { useDispatch, useSelector } from 'react-redux'
import ReportedBugsTable from '../../components/Table/BugReportsTable/BugsTable'
import { fetchBugsBySection } from '../../services/operations/bugs'
import TablePagination from '../../components/Table/TablePagination'

const ReportedBugs = ({ category, status, startDate, endDate, priority }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [content, setContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const bugsData = useSelector((state) => state.bugs[`${category}Bugs`]?.[status.trim().replace(' ', '_')] || {});

    const itemsPerPage = 25;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const formatDate = (date) => {
        return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
    };

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
   useEffect(() => {

    dispatch(fetchBugsBySection(category, currentPage, status, formattedStartDate, formattedEndDate, priority));

}, [dispatch, category, currentPage, status, startDate, endDate, priority, formattedStartDate, formattedEndDate,]);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderCategoryBugs = (bugs) => {
        return (
            <div className="mb-8">
                {bugsData.data && bugsData.data.length > 0 ? (
                    <>
                        <ReportedBugsTable bugs={bugsData.data} />
                        <div className="  border dark:border-dark_40 bg-[#F4F6F8] dark:bg-[#141414] border-gray-200  rounded-b-2xl mt-3">
                            <TablePagination
                                itemsPerPage={itemsPerPage}
                                totalItems={bugsData.totalRecords}
                                currentPage={currentPage}
                                paginate={paginate}
                                indexOfFirstItem={indexOfFirstItem}
                                indexOfLastItem={indexOfLastItem}
                            />
                        </div>
                    </>
                ) : (
                    <div className='flex justify-center items-center text-3xl pt-4 dark:text-zinc-300'>
                        No Bugs !!!
                    </div>
                )}
            </div>
        );
    };

    return (
        <main className="dark:bg-dark font-primary h-screen overflow-y-auto no-scrollbar bg-[#fafafa]">
            {renderCategoryBugs(bugsData.data)}
        </main>
    );
};

export default ReportedBugs;