const BASE_URL = process.env.REACT_APP_BACKEND_URL

export const authEndpoints = {
    LOGIN_IN: BASE_URL + '/auth/login',
    LOGOUT_IN: BASE_URL + '/auth/logout',
}

export const bugEndpointsNew = {
    GET_ALL_BUGS: BASE_URL + '/bugs/get-bugs',
    GET_BUG_BY_ID: BASE_URL + '/bugs/get-bugs',
    GET_BUGS_BY_SECTION: BASE_URL + '/bugs/sections',
    GET_BUG_COUNTS: BASE_URL + '/bugs/sections',
    UPDATE_BUG: BASE_URL + '/bugs/update-bugs',
    ASSIGN_BUG_TO_USER: BASE_URL + '/bugs/assign-bugs',
    ADD_USER_TO_BUG_TEAM: BASE_URL + '/bugs/users',
    DELETE_USER_FROM_BUG_TEAM: BASE_URL + '/bugs/users',
    GET_BUG_TEAM_MEMBERS: BASE_URL + '/bugs/users',
    GET_ASSIGNED_BUGS: BASE_URL + '/bugs/assigned-bugs',
    GET_BUG_STATS: BASE_URL + '/bugs/stats',
    ADD_COMMENT: BASE_URL + '/bugs/',
    SEND_EMAIL: BASE_URL + '/bugs/send-email',
    REPORT_BUG: BASE_URL + '/bugs/report-bug',
    DELETE_BUG: BASE_URL + '/bugs/'
};
