import React from 'react'
import BugTeamTableItem from './TeamTableItems'

const BugTeamTable = ({ team }) => {
    return (
        <div className="  font-primary  relative w-full">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Member ID</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Username</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {
                            team?.map((data, index) => {
                                return (
                                    <BugTeamTableItem
                                        key={data?.id}
                                        id={data?.id}
                                        num={index + 1}
                                        name={data?.username}
                                        // setModalOpen={setModalOpen}
                                    />
                                )
                            })
                        }                      
                    </table>
                </div>
            </div>
        </div>
    )
}

export default BugTeamTable


