// import React, { useState } from 'react';

import { Classic } from "@theme-toggles/react";
import "@theme-toggles/react/css/Classic.css";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useDarkMode from '../../hooks/useDarkMode';
import UserMenu from "../UserMenu/UserMenu";
// import { logout } from '../../../services/operations/authAPi';

function Header({
    sidebarOpen,
    setSidebarOpen
}) {

    const [darkTheme, setDarkTheme] = useDarkMode();


    const { token } = useSelector((state) => state.auth)
    const { username } = useSelector((state) => state.auth)

    return (
        <header className="sticky bg-white dark:bg-dark top-0 border-b dark:border-lead500 border border-lead100  z-30">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 -mb-px">
                    <div className="flex">
                        <button
                            className="text-gray-500 hover:text-gray-600 lg:hidden"
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <rect x="4" y="5" width="16" height="2" />
                                <rect x="4" y="11" width="16" height="2" />
                                <rect x="4" y="17" width="16" height="2" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex items-center space-x-4">
                        <div className="ml-4 flex justify-center items-center p-1 rounded-lg border-brand_10 bg-[#FDEDEA] dark:bg-[#ee4c2b39] dark:border-[#ee4c2b88] border-2">
                            <Classic toggled={darkTheme} toggle={setDarkTheme} duration={750} />
                        </div>
                        {token ? (
                            <UserMenu
                                username={username}
                                align='right'
                            />
                        ) : (
                            <Link to='/' className="btn-brand-2">
                                <span className="relative">Login</span>
                            </Link>
                        )}
                    </div>

                </div>
            </div>
        </header >
    );
}

export default Header;