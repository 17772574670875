import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addBugComment, updateBugStatus } from '../../services/operations/bugs'; 
import { toast } from 'react-hot-toast'; 
import { htmlToMarkdown } from './Parser';
import { updateBugStatusRedux } from '../../slices/bugSlice';

const CommentEditor = ({ bugId, email, title }) => {
  const [editorContent, setEditorContent] = useState("");
  const dispatch = useDispatch();

  const handleSendComment = () => {
    const plainTextContent = htmlToMarkdown(editorContent).trim();

    if (!plainTextContent) {
      toast.error("Comment cannot be empty");
      return;
    }

    dispatch(addBugComment(bugId, plainTextContent, email, title));
    dispatch(updateBugStatus(bugId, 'awaiting reply'));
    dispatch(updateBugStatusRedux({ report_id: bugId, status: 'awaiting reply' }));    
    setEditorContent(""); 
  };

  return (
    <div>
      <ReactQuill
        value={editorContent}
        onChange={setEditorContent}
        placeholder="Add a comment..."
        theme="snow"
        className='bg-white dark:bg-zinc-900 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-xl'
      />
      <button 
        className="mt-4 px-4 py-2 bg-brand text-white rounded transition w-full"
        onClick={handleSendComment}
      >
        Send Comment
      </button>
    </div>
  );
};

export default CommentEditor;
