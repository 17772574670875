import React, { useState } from 'react'
import { deleteUserFromBugTeam } from '../../../services/operations/bugs'
import { useDispatch } from 'react-redux'
import DeleteModal from '../../Modal/DeleteModal'
import { AlertTriangle } from 'lucide-react';

const BugTeamTableItem = (props) => {
    const { id, name } = props
    const dispatch = useDispatch()
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

    const handleUserDeleteModal = (e) => {
        e.stopPropagation()

        setModalDeleteOpen(true);
    }
    
    const handleDeleteUser = (e) => {
        dispatch(deleteUserFromBugTeam(name, setModalDeleteOpen))
    }

    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {id}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {name}
                    </div>
                </td>   
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={handleUserDeleteModal} className='btn bg-red-500 border-red-500 text-white transition-all duration-300 ease-in-out'>Delete</button>
                    </div>
                </td>
            </tr>
            <DeleteModal id="delete-modal" modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                <div className="px-5 pt-4 pb-1">
                    <div className="flex justify-center flex-col items-center">
                        <div className="p-2 rounded-full bg-red-500/20 w-fit">
                            <AlertTriangle className="stroke-red-600" size={32} />
                        </div>
                        <p className="font-bold text-2xl mt-1">Are you sure?</p>
                        <div className="flex flex-col justify-center items-center mt-6">
                            <span>The user will be removed from the Bugs Team.</span>
                        </div>
                        <div className="mt-4">
                            <button onClick={handleDeleteUser} className="w-full text-md rounded-lg relative inline-flex items-center justify-center px-3.5 py-2 m-1 cursor-pointer border-b-2 border-l-2 border-r-2  active:border-red-700 active:shadow-none shadow-lg bg-gradient-to-tr from-red-600 to-red-500 hover:from-red-500 hover:to-red-500  border-red-700 text-white">Delete</button>
                        </div>
                    </div>
                </div>
            </DeleteModal>
        </tbody >
    )
}

export default BugTeamTableItem